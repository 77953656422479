//the goal of this component is to redirect the client if isn't logged in => to the page /login

import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
// import { Login } from "../Login";
// import { onLodingSignIn } from "../../redux/actions/authActions";

const ProtectedRoute = ({
  isAuth,
  isLocked,
  component: Component,
  ...rest
}) => {
  // ...rest =>means all of the rest props
  console.log("this Protected route :");
  console.log("isAuth :" + isAuth);
  console.log("isLocked :" + isLocked);

  return (
    <Route
      {...rest}
      render={props =>
        isAuth && isAuth=== true ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isAuth: authReducer.isAuth,
    isLocked: authReducer.isLocked
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
