import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import Loadable from "react-loadable";
import BeatLoader from "react-spinners/BeatLoader"

import ProtectedRoute from "./pages/protectedRoutes/protectedRoute";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center mx-auto" style={{marginTop:250}}><BeatLoader color="#067BE3" loading={true} size={12}/></div>

  );

// Home
const Home = Loadable({
  loader: () => import("./pages/home"),
  loading
});

//Login page
const Login = Loadable({
  loader: () => import("./pages/login"),
  loading
});
//Signup page
const Signup = Loadable({
  loader: () => import("./pages/signup"),
  loading
});
//Page404
const Page404 = Loadable({
  loader: () => import("./pages/404"),
  loading
});
const GetAccount = Loadable({
  loader: () => import("./pages/forgotPassword/getAccount"),
  loading
});

const GetSecurityCodeByEmail = Loadable({
  loader: () => import("./pages/forgotPassword/getCodeSent"),
  loading
});
const GetSecurityCodeBySMS = Loadable({
  loader: () => import("./pages/forgotPassword/getCodeSent"),
  loading
});

const ChangePassword = Loadable({
  loader: () => import("./pages/forgotPassword/changePassword"),
  loading
});

const ContactAdmin = Loadable({
  loader: () => import("./pages/forgotPassword/contactAdmin"),
  loading
});


function App() {
 
  return (
    <HashRouter>
    <Switch>
    <Route
            exact
            path="/getAccount"
            name="getAccount Page"
            component={GetAccount}
          />
          <Route
            exact
            path="/getSecurityCodeByEmail"
            name="GetSecurityCodeByEmail Page"
            component={GetSecurityCodeByEmail}
            //render={props => <GetSecurityCodeByEmail {...props} />}
          />
          <Route
            exact
            path="/getSecurityCodeBySMS"
            name="GetSecurityCodeBySMS Page"
            component={GetSecurityCodeBySMS}
            //render={props => <GetSecurityCodeBySMS {...props} />}
          />
          <Route
            exact
            path="/changePassword"
            name="ChangePassword Page"
            component={ChangePassword}
          />
          <Route
            exact
            path="/contactAdmin"
            name="GetSecurityCode Page"
            component={ContactAdmin}
          />
         
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/signup" name="Signup" component={Signup} />
          <Route exact path="/login" name="Administration Login" component={Login} />
          <ProtectedRoute path="/" name="Home page" component={Home} />
        </Switch>
    </HashRouter>
  );
}



export default  (App);
